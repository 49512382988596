@import url(./inter.less);

html {
  height: 100%;
  overflow: hidden; // The content of the DatasetSettings page is too high, which will cause scroll bars to appear on the html tags. Setting the maximum height in DatasetSettings does not work either. I don't understand.
}

body {
  font-family: Inter;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.ant-app {
  height: 100%;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(219, 218, 218);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.samrukThemeAvatar {
  display: none;
}
body.samrukTheme {
  header {
    .defaultLogo {
      display: none;
    }
    .samrukLogo {
      display: block;
      img {
        width: 130px;
        max-width: 100%;
        height: 50px;
      }
    }
  }
  .defaultThemeAvatar {
    display: none !important;
  }
  .samrukThemeAvatar {
    display: block;
  }
}

label.ant-form-item-no-colon {
  text-wrap: auto !important;
  min-height: 32px !important;
  height: auto !important;
  line-height: 1.3 !important;
}

@hack: true; @import "~@/less/index.less";